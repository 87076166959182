import * as React from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../components/SEO"
import {
  Container,
  ContentWithPaddingXl,
} from "../components/Common/Containers"
import { PolicyText } from "../components/Common/PolicyText"
import { AltHeading } from "../components/Common/Headings"
import ScrollToTop from "../components/Common/ScrollToTop"

const TermsOfUse = ({ path, ...rest }) => {
  const { language } = useTranslation().i18n

  const data = useStaticQuery(graphql`
    query($content: String = "terms-of-use") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              lang
              heading
            }
            html
          }
        }
      }
    }
  `)

  const filteredEdge = data.allMarkdownRemark.edges.find(
    edge => edge.node.frontmatter.lang === language
  )

  const { heading } = filteredEdge.node.frontmatter
  const { html } = filteredEdge.node

  return (
    <>
      <Seo title={heading} />
      <Container id="terms-of-use">
        <ScrollToTop />

        <ContentWithPaddingXl>
          <AltHeading>{heading}</AltHeading>
          <PolicyText dangerouslySetInnerHTML={{ __html: html }} />
        </ContentWithPaddingXl>
      </Container>
    </>
  )
}

export default TermsOfUse
